import React from "react";
import { Button } from "antd";
import SVGIcon from "../../../utils/SVGIcon";
import { ButtonProps } from "../../Buttons/interface/ButtonInterface";
import { useLocation } from "react-router-dom";
import { GrantStatusListDisplay, Notification } from "../../../config/Global";

const EditButton: React.FC<Partial<ButtonProps>> = ({
  action,
  data,
  permission,
  ...rest
}) => {
  const location = useLocation();
  if (!permission) {
    return null;
  }

  delete rest?.permissionKey;

  return (
    <Button
      type="text"
      title={"Edit"}
      className="editIcon"
      onClick={() => {
        if (location.pathname === "/grants" && data?.status !== null) {
          Notification.error({
            message: `Your Status is ${GrantStatusListDisplay[data?.status]} approved and cannot be edited`,
          });
        } else {
          action && action(data)
        }

      }}
      {...rest}
    >
      <SVGIcon icon="edit" />
    </Button>
  );
};

export default EditButton;
