import { ColDef } from "ag-grid-community";
import {
	srNoColumn,
	actionColumn,
} from "../../../../../components/AgGridWrapper/utils/commonColumns";
import { renderNA, setDateFilterDefs } from "../../../../../components/AgGridWrapper/utils/commonFunctions";
import { EntryFromOptions, EntryFromOptionsTypeDisplay, dateFormatter, genderOptions, genderOptionsDisplay } from "../../../../../config/Global";
import { useTranslation } from "react-i18next";


const columnDefs = (): ColDef[] => {
	const { t } = useTranslation();
	const firstnameColumn: ColDef = {
		field: "first_name",
		headerName: `${t("CITIZENMANAGEMENT.FIRST_NAME")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.first_name);
		},
	};
	const middlenameColumn: ColDef = {
		field: "middle_name",
		headerName: `${t("CITIZENMANAGEMENT.MIDDLE_NAME")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.middle_name);
		},
	};
	const lastnameColumn: ColDef = {
		field: "last_name",
		headerName: `${t("CITIZENMANAGEMENT.LAST_NAME")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.last_name);
		},
	};
	const emailColumn: ColDef = {
		field: "email",
		headerName: `${t("GENERAL.EMAIL")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.email);
		},
	};
	const genderColumn: ColDef = {
		field: "gender",
		headerName: `${t("GENERAL.GENDER")}`,
		cellRenderer: (props: any) => {
			return renderNA(genderOptionsDisplay[props.data?.gender]);
		},
		filter: "agSetColumnFilter",
		filterParams: {
			values: (params: any) => {
				if (genderOptions) {
					params.success(genderOptions.map(x => x.name));
				}
			},
			buttons: ["apply", "reset"],
			closeOnApply: true,
		},

	}
	const whatsNoColumn: ColDef = {
		field: "whatsapp_number",
		headerName: `${t("CITIZENMANAGEMENT.WHATSAPP_NUMBER")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.whatsapp_number);
		},
	};
	const phoneColumn: ColDef = {
		field: "phone",
		headerName: `${t("GENERAL.MOBILE_NUMBER")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.phone);
		},
	};
	const villageColumn: ColDef = {
		field: "village_name",
		headerName: `${t("GENERAL.VILLAGES")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.village_name);
		},
	};

	const talukaColumn: ColDef = {
		field: "taluka_name",
		headerName: `${t("GENERAL.TALUKA")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.taluka_name);
		},
	};

	const pincodeColumn: ColDef = {
		field: "pincode",
		headerName: `${t("GENERAL.PINCODES")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.pincode);
		},
	};
	const DOBColumn: ColDef = {
		field: "dob",
		headerName: `${t("GENERAL.DATE_OF_BIRTH")}`,
		sortable: true,
		width: 200,
		minWidth: 200,
		cellRenderer: (props: any) => {
			return props.data?.dob ? dateFormatter(props.data?.dob) : "N/A";
		},
		...setDateFilterDefs()
	};
	const Entry_fromColumn: ColDef = {
		field: "entry_from",
		headerName: `${t("CITIZENMANAGEMENT.Entry_from")}`,
		cellRenderer: (props: any) => {
			return renderNA(EntryFromOptionsTypeDisplay[props.data?.entry_from]);
		},
		filter: "agSetColumnFilter",
		filterParams: {
			values: (params: any) => {
				if (EntryFromOptions) {
					params.success(EntryFromOptions.map(x => x.name));
				}
			},
			buttons: ["apply", "reset"],
			closeOnApply: true,
		},
	};

	return [

		srNoColumn(t),
		firstnameColumn,
		middlenameColumn,
		lastnameColumn,
		emailColumn,
		DOBColumn,
		genderColumn,
		whatsNoColumn,
		phoneColumn,
		villageColumn,
		talukaColumn,
		pincodeColumn,
		Entry_fromColumn,
		{ ...actionColumn(t), width: 165, minWidth: 165 },
	];
};
export default columnDefs;
