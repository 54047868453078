const baseUrl = "/v1/admin/citizens";

export const API_URL = {
  LIST: `${baseUrl}`,
  CREATE: `${baseUrl}/create`,
  UPDATE: (id: number): string => `${baseUrl}/${id}/update`,
  DELETE: (id: number): string => `${baseUrl}/${id}/delete`,
  DETAILS: (id: number): string => `${baseUrl}/${id}/details`,
  CHANGE_STATUS: (id: number): string => `${baseUrl}/${id}/change-status`,
  CITIZEN_DETAILS: `${baseUrl}/get-citizen-details`,
  CHECKMOBILENO: `${baseUrl}/check-mobile-no`,
};