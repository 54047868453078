import React, { useEffect, useState } from 'react'
import ToolBar from '../../../../components/ToolBar'
import { ActivityBreadcrumb } from '../../../../config/BreadcrumbConfig'
import { useAppDispatch, useAppSelector } from '../../../../store/app'
import { Link, useParams } from 'react-router-dom'
import { deleteMeetingComment, deleteMeetingCommentAttachment, editMeetingComment, meetingComments, meetingDetails, meetingList } from '../utils/meetingSlice'
import { Badge, Button, Col, Form, Image, Row, Switch, Typography, Upload, message } from 'antd'
import ContentBox from '../../../../components/ContentBox/ContentBox'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { EntryFromOptions, checkPrivileges, dataToFormDataConverterNew, genderOptions, validateFields, villageType } from '../../../../config/Global'
import { CONSTANT } from '../../../../config/Constant'
import dayjs from 'dayjs';
import { assignErrorToInput } from '../../../../store/api'
import { rules } from './Form/rules'
import { FilePdfOutlined, EditOutlined, DeleteOutlined, PaperClipOutlined } from "@ant-design/icons";
import CommonModal from '../../../../components/Modals/CommonModal'
import { ModuleInfoProps } from '../../../../config/InterfacesAndTypes'
import { setPincodeList } from '../../Administration/PincodeManagement/utils/slice'
import { setTalukaList } from '../../Administration/TalukaManagement/utils/slice'
import { setVillageList } from '../../Administration/VillageVsWard/utils/slice'
import { useTranslation } from 'react-i18next'
import { setRelation } from '../utils/slice'

const MeetingView: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const { t } = useTranslation();
	const { Text } = Typography;
	const dispatch = useAppDispatch();
	const { villageList } = useAppSelector(
		state => state.village
	);
	const { pincodeList } = useAppSelector(
		state => state.pincode
	);
	const { talukaList } = useAppSelector(
		state => state.taluka
	);
	const { relation } = useAppSelector(
		state => state.citizen
	)
	const userDetail = useAppSelector((state) => state.auth.userDetail);
	const { id }: any = useParams();
	const [form] = Form.useForm();
	const [commentForm] = Form.useForm();
	const [editCommentForm] = Form.useForm();
	const [files, setFiles] = useState<any>([]);
	const [getMeetingComments, setGetMeetingComments] = useState<any>([]);
	const [data, setData] = useState<any>(); //this state use for comment edit and view
	const [openFromModal, setOpenFromModal] = useState<any>();
	const [deleteAttachment, setDeleteAttachment] = useState<any>();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [deleteAttachmentSaving, setDeleteAttachmentSaving] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [modalsaving, setModalSaving] = useState<boolean>(false);
	const [showMoreBtn, setShowMoreBtn] = useState(false);
	const [commentModalBtndisabled, setCommentModalBtnDisabled] = useState<boolean>(true);


	useEffect(() => {
		dispatch(meetingDetails(id))
			.then((res: any) => {
				setShowMoreBtn(res?.meetingComment?.show_more)
				if (res?.meetingComment?.meetingCommentList?.length > 0) {
					setGetMeetingComments(res.meetingComment.meetingCommentList)
				}
				if (res?.citizenDetails?.taluka) {
					dispatch(setTalukaList([res.citizenDetails.taluka]))
				}
				if (res?.citizenDetails?.pincode) {
					dispatch(setPincodeList([res.citizenDetails.pincode]))
				}
				if (res?.citizenDetails?.village) {
					dispatch(setVillageList([res.citizenDetails.village]))
				}
				if (res?.citizenMeeting?.attachment?.length > 0) {
					setFiles(res?.citizenMeeting?.attachment)
				}
				if (res?.citizenDetails?.citizen_family) {
					dispatch(setRelation([res?.citizenDetails?.citizen_family]))
				}
				form.setFieldsValue({
					...res.citizenDetails,
					dob: res.citizenDetails?.dob ? dayjs(res.citizenDetails.dob) : null
				});
				form.setFieldsValue({
					type: res?.citizenMeeting?.type,
					description: res?.citizenMeeting?.description,
					date: res?.citizenMeeting?.date ? dayjs(res.citizenMeeting.date) : null
				});
				commentForm.setFieldsValue({
					send_status: 1
				})
			})
			.catch((e: any) => e)
	}, [id])

	const checkFileType = (file: any) => {
		const isLt5M = file.size / 1024 / 1024 < 1;
		const acceptedFormats = [
			"application/pdf",
			"image/png",
			"image/jpeg",
			"image/jpg",
		];

		if (!acceptedFormats.includes(file.type)) {
			message.error(CONSTANT.INVALID_FILE);
			editCommentForm.setFields([{ name: "attachment", errors: [CONSTANT.INVALID_FILE] }]);
			return Upload.LIST_IGNORE;
		}
		if (!isLt5M) {
			message.error(CONSTANT.BIGGER_SIZE);
			editCommentForm.setFields([{ name: "attachment", errors: [CONSTANT.BIGGER_SIZE] }]);
			return Upload.LIST_IGNORE;
		} else {
			return false;
		}
	};

	const validateForm = () => {
		validateFields(commentForm, setDisabled);
	};
	const validateEditCommentForm = () => {
		validateFields(editCommentForm, setCommentModalBtnDisabled);
	};

	const openModal = (data: any, action: any) => {
		setData(data)
		if (action === "viewAttachment") {
			setOpenFromModal(action)
		} else if (action === "deleteComment") {
			setOpenFromModal(action)
		} else if (action === "editComment") {
			setOpenFromModal(action)
			editCommentForm.setFieldsValue({
				...data,
			});
		}
	}

	const closeModal = () => {
		setOpenFromModal(null)
		setData(null)
		setCommentModalBtnDisabled(true)
		// setDisabled(true)
	}
	const closeAttachmentDelete = () => {
		setDeleteAttachment(null)
	}

	const openAttachmentDelete = (data: any) => {
		setDeleteAttachment(data)
		return false
	}

	const commentRemoveImage = (id: any) => {
		const removedImage = commentForm.getFieldValue("attachment").filter((item: any) => item?.uid !== id?.uid)
		commentForm.setFieldsValue({
			attachment: removedImage
		})
	}
	const handleRemoveImage = () => {

		if (!deleteAttachment.id) {
			const removedImage = editCommentForm.getFieldValue("attachment").filter((item: any) => item?.uid !== deleteAttachment.uid)
			editCommentForm.setFieldsValue({
				attachment: removedImage
			})
			closeAttachmentDelete()
		} else {
			setDeleteAttachmentSaving(true);
			const removedImage = editCommentForm.getFieldValue("attachment").filter((item: any) => item?.id !== deleteAttachment.id)
			dispatch(deleteMeetingCommentAttachment(deleteAttachment.id))
				.then(() => {
					setDeleteAttachmentSaving(false)
					editCommentForm.setFieldsValue({
						attachment: removedImage
					})
					const updatedList = getMeetingComments.map((item: any) => {
						if (item.id === data.id) {
							const updatedAttachments = item.attachment.filter(
								(attachment: any) => attachment.id !== deleteAttachment.id
							);
							if (updatedAttachments.length <= 0) {
								closeModal();
							}
							return { ...item, attachment: updatedAttachments };
						}
						return item;
					});

					setGetMeetingComments(updatedList);
					closeAttachmentDelete()
				}).catch((error: any) => {
					assignErrorToInput(form, error?.STATUS);
					setDeleteAttachmentSaving(false)
				})
				.finally(() => setDeleteAttachmentSaving(false));
		}
	}

	const closeDeletAttachmentModal = () => {
		setDeleteAttachment(null)
		setCommentModalBtnDisabled(true)
	}

	const showMoreComment = () => {
		const lastIndex = getMeetingComments.length - 1;
		const lastcontant = getMeetingComments[lastIndex]
		const payload = {
			meeting_id: id,
			last_comment_id: lastcontant.id,
			per_page: CONSTANT.COMMENT_PER_PAGE
		}
		dispatch(meetingList(payload))
			.then((res: any) => {
				setGetMeetingComments((prev: any) => [...prev, ...res.rows])
				setShowMoreBtn(res?.show_more)
			})
			.catch((error: any) => error)
	}
	const handleFormSubmit = (data: any) => {
		setSaving(true);
		data.meeting_id = id
		data.citizen_id = form.getFieldValue("id")

		const formData = dataToFormDataConverterNew(data)
		dispatch(meetingComments(formData))
			.then((res: any) => {
				setSaving(false)
				setGetMeetingComments((prev: any) => [res.data, ...prev])
				commentForm.resetFields()
				commentForm.setFieldsValue({
					send_status: 1
				});
				setDisabled(true)
			})
			.catch((error: any) => {
				assignErrorToInput(editCommentForm, error?.STATUS);
				setSaving(false)
				setDisabled(true)
			})
			.finally(() => setSaving(false));
	};
	const resetComments = () => {
		commentForm.resetFields()
		commentForm.setFieldsValue({
			send_status: 1,
			attachment: []
		})
		setDisabled(true)
	}

	const handleCommentForm = (editCommentData: any) => {

		setModalSaving(true)
		if (openFromModal === "deleteComment") {
			dispatch(deleteMeetingComment(data.id))
				.then(() => {
					setModalSaving(false)
					const deleteCommentData = getMeetingComments.filter((item: any) => item.id !== data.id)
					setGetMeetingComments(deleteCommentData)
					closeModal()
				})
				.catch((error: any) => {
					assignErrorToInput(form, error?.STATUS);
					setModalSaving(false)
				})
				.finally(() => setModalSaving(false));
		}
		if (openFromModal === "editComment") {
			editCommentData.meeting_id = id
			editCommentData.citizen_id = form.getFieldValue("id")
			if (editCommentData?.attachment.length > 0) {
				editCommentData.attachment = editCommentData.attachment.filter((obj: any) => !('id' in obj));
			}
			const formData = dataToFormDataConverterNew(editCommentData)

			dispatch(editMeetingComment(formData, data.id))
				.then((res: any) => {
					const indexToUpdate = getMeetingComments.findIndex((item: any) => item.id === data.id);

					if (indexToUpdate !== -1) {
						const updatedMeetingComments = [...getMeetingComments];
						updatedMeetingComments[indexToUpdate] = res.data;
						setGetMeetingComments(updatedMeetingComments);
					}

					setModalSaving(false);
					closeModal();

				})
				.catch((error: any) => {
					assignErrorToInput(form, error?.STATUS);
					setModalSaving(false)
				})
				.finally(() => setModalSaving(false));
		}
	}
	return (
		<>
			<ToolBar breadcrumbs={ActivityBreadcrumb(t)}></ToolBar>
			<ContentBox>
				<Row gutter={15}>
					<Col sm={24} md={24} xl={12} xxl={12} className='citizenMeetingView' >
						<div className='citizenMeeting p-20'>
							{checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_COMMENT_CREATE`) &&
								<FormBox
									form={commentForm}
									id={"commentForm"}
									onFinish={handleFormSubmit}
									onValuesChange={validateForm}
								>
									<div className='comment_section'>
										<div className='comment'>
											<div className='commentTitle'>
												<p>{t("CITIZENMANAGEMENT.ADD_COMMENT_P")}</p>
											</div>
											<div className='sendStatus'>
												<p className='sendStatusTitle'>
													{t("CITIZENMANAGEMENT.SEND_STATUS_TO_CITIZEN_P")}
													<span className='sendStatusForm'>{t("GENERAL.NO")}
														<Form.Item name="send_status" valuePropName="checked">
															<Switch
																checked={(commentForm.getFieldValue('send_status') === 1) ? true : false}
																onChange={(e) => {
																	commentForm.setFieldsValue({
																		send_status: e ? 1 : 0
																	})
																}}
															/>
														</Form.Item>
														{t("GENERAL.YES")}
													</span>
												</p>
											</div>
										</div>
										<div className='commentArea'>
											<InputBox.TextArea
												name="comment"
												rules={rules.comment()}
											/>
											<InputBox.Upload
												required={false}
												name="attachment"
												maxCount={5}
												multiple={true}
												listType="picture"
												onRemove={commentRemoveImage}
												className="inputUpload"
												fileList={commentForm.getFieldValue("attachment")}
												beforeUpload={checkFileType}
											/>

										</div>
									</div>

									<div className='citizenFormBtn'>
										<Button
											key="1"
											disabled={disabled}
											form="commentForm"
											loading={saving}
											htmlType="submit"
											type="primary"
										>
											{t("GENERAL.SAVE_BUTTON")}
										</Button>
										<Button
											key="2"
											htmlType="button"
											className="cancelBtn"
											onClick={() => resetComments()}
										>
											{t("GENERAL.CANCEL_BUTTON")}
										</Button>
									</div>
								</FormBox>
							}
							{getMeetingComments && getMeetingComments?.length > 0 &&
								getMeetingComments.map((item: any, index: number) => {
									return (
										<Row key={index} className='comment_section' gutter={5} align={'middle'}>
											<Col sm={24} md={5}    >
												<div className='commentTitle'>
													<p>{t("CITIZENMANAGEMENT.DATE_COMMENT_TILE")} : {item?.created_at && dayjs(item?.created_at).format(CONSTANT.DATE_FORMAT)}</p>
												</div>
											</Col>
											<Col sm={24} md={13}   >
												<p className='sendStatusTitle'>
													{t("CITIZENMANAGEMENT.SEND_STATUS_TO_CITIZEN_P")}
													<span className='sendStatusForm'>
														{
															item.send_status === 1 ?
																<Text type="success">{t("GENERAL.YES")}</Text>
																:
																<Text type="danger">{t("GENERAL.NO")}</Text>
														}
													</span>
												</p>
											</Col>
											<Col sm={24} md={6}   >
												<div className='actionIcon'>
													{/* <SendOutlined /> */}
													{checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_COMMENT_UPDATE`) &&
														<div onClick={() => openModal(item, "editComment")}>
															<EditOutlined />
														</div>
													}
													{item?.attachment?.length > 0 &&
														<div onClick={() => openModal(item, "viewAttachment")}>
															<Badge dot={true}>
																<PaperClipOutlined />
															</Badge>
														</div>
													}
													{checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_COMMENT_DELETE`) &&
														<div onClick={() => openModal(item, "deleteComment")}>
															<DeleteOutlined />
														</div>
													}
												</div>
											</Col>
											<Col span={24}>
												<p className='commentTextArea'>{item.comment}</p>
											</Col>
										</Row>
									)
								})
							}
						</div>
						{showMoreBtn &&
							<Button
								key="1"
								loading={saving}
								type="primary"
								onClick={showMoreComment}
								className='showMore'
							>
								{t("CITIZENMANAGEMENT.SHOW_MORE_BUTTON")}
							</Button>
						}
					</Col>
					<Col sm={24} md={24} xl={12} xxl={12} className='citizenMeetingView'>
						<div className='citizenMeeting'>
							<div className='tag'><span>{`${t("CITIZENMANAGEMENT.Visitor")} ${t("GENERAL.REGISTER")}`}</span></div>
							<FormBox
								form={form}
								id={"citizenMeetingView"}
								className='borderLessForm'

							>
								<Row gutter={10}>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Text
											label={t("CITIZENMANAGEMENT.ID")}
											name="id"
											placeholder={t("CITIZENMANAGEMENT.ID")}
											disabled
											hidden
										/>
										<InputBox.Text
											label={`${t("CITIZENMANAGEMENT.FIRST_NAME")}`}
											name="first_name"
											placeholder={`${t("CITIZENMANAGEMENT.FIRST_NAME")}`}
											disabled
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Text
											label={`${t("CITIZENMANAGEMENT.MIDDLE_NAME")}`}
											name="middle_name"
											placeholder={`${t("CITIZENMANAGEMENT.MIDDLE_NAME")}`}
											disabled
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Text
											label={`${t("CITIZENMANAGEMENT.LAST_NAME")}`}
											name="last_name"
											placeholder={`${t("CITIZENMANAGEMENT.LAST_NAME")}`}
											disabled
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Text
											name="email"
											label={t("GENERAL.EMAIL_ID")}
											placeholder={t("GENERAL.EMAIL_PLACEHOLDER")}
											disabled
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.DatePicker
											disabled
											allowClear={false}
											name="dob"
											label={t("GENERAL.DATE_OF_BIRTH")}
											placeholder={t("GENERAL.DATE_OF_BIRTH")}
										/>
									</Col>

									<Col sm={24} md={12} xxl={12} >
										<InputBox.Select
											name="gender"
											label={t("GENERAL.GENDER")}
											placeholder={t("GENERAL.GENDER")}
											disabled
											options={{
												list: genderOptions,
												valueKey: "id",
												textKey: "name",
											}}
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Text
											label={t("CITIZENMANAGEMENT.WHATSAPP_NUMBER")}
											name="whatsapp_number"
											placeholder={t("CITIZENMANAGEMENT.WHATSAPP_NUMBER")}
											disabled
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Text
											label={t("GENERAL.MOBILE_NUMBER")}
											name="phone"
											placeholder={t("GENERAL.MOBILE_NUMBER")}
											disabled
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Select
											name="relation"
											label={`${t("CITIZENMANAGEMENT.relation")}`}
											placeholder={`${t("CITIZENMANAGEMENT.relation")}`}
											disabled
											options={{
												list: relation,
												valueKey: "id",
												textKey: "name",
											}}
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Select
											name="pincode_id"
											label={t("GENERAL.PINCODES")}
											placeholder={t("GENERAL.PINCODES")}
											disabled
											options={{
												list: pincodeList,
												valueKey: "id",
												textKey: "pincode",
											}}
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Select
											name="taluka_id"
											label={t("GENERAL.TALUKA")}
											placeholder={t("GENERAL.TALUKA")}
											disabled
											options={{
												list: talukaList,
												valueKey: "id",
												textKey: "name",
											}}
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Select
											name="village_id"
											label={t("GENERAL.VILLAGES")}
											placeholder={t("GENERAL.VILLAGES")}
											disabled
											options={{
												list: villageList,
												valueKey: "id",
												textKey: "name",
											}}
										/>
									</Col>
									<Col sm={24} md={12} xl={12} xxl={12} >
										<InputBox.Select
											name="village_type_id"
											label={t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}
											placeholder={t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE_PLACEHOLDER")}
											disabled
											options={{
												list: villageType,
												valueKey: "id",
												textKey: "name",
											}}
										/>
									</Col>
									<Col sm={24} md={12} xxl={12} >
										<InputBox.Select
											disabled
											name="entry_from"
											label={`${t("CITIZENMANAGEMENT.Entry_from")}`}
											placeholder={`${t("CITIZENMANAGEMENT.Entry_from")}`}
											options={{
												list: EntryFromOptions,
												valueKey: "id",
												textKey: "name",
											}}
										/>
									</Col>

									<Col xs={{ span: 24 }} >
										<InputBox.Text
											label={t("GENERAL.ADDRESS")}
											name="address"
											placeholder={t("GENERAL.ADDRESS")}
											disabled
										/>
									</Col>
								</Row>
							</FormBox>
						</div>
						<div className='citizenMeeting'>
							<div className='tag'><span>{t("GENERAL.MEETING_DETAILS")}</span></div>
							<FormBox
								form={form}
								id={"citizenMeetingView"}
								className='borderLessForm'

							>
								<Row gutter={30}>
									<Col sm={24} md={12} xxl={8} >
										<InputBox.Text
											label={t("CITIZENMANAGEMENT.TYPE_OF_MEETING")}
											name="type"
											placeholder={t("CITIZENMANAGEMENT.TYPE_OF_MEETING")}
											disabled

										/>
									</Col>
									<Col sm={24} md={12} xxl={8} >
										<InputBox.DatePicker
											name="date"
											label={t("CITIZENMANAGEMENT.DATE_OF_MEETING")}
											placeholder={t("CITIZENMANAGEMENT.DATE_OF_MEETING")}
											format={CONSTANT.DATE_FORMAT}
											allowClear={false}
											disabled
										/>
									</Col>

									<Col sm={24} md={24} xl={24} xxl={8} >
										<label className='formLabel'>{t("GENERAL.ATTECHMENT")}</label>
										<div className='pdfView'>
											{files && files.length > 0 ?
												files.map((item: any, index: number) => {
													const fileExtension: any = item.url.split('.').pop();
													return (
														<span key={index}>
															{fileExtension === "pdf" ?
																<Link to={item.url} target="_blank" rel="noopener noreferrer" >
																	<FilePdfOutlined />
																</Link>

																: <Image width={40} height={40} src={item.url} />
															}
														</span>
													)

												})
												: `${t("GENERAL.NO_FILE_UPLOADED")}`
											}
										</div>
									</Col>
									<Col sm={24} md={24} xxl={24} >
										<InputBox.TextArea
											label={t("GENERAL.MEETING_DETAILS")}
											name="description"
											placeholder={t("GENERAL.MEETING_DETAILS")}
											rows={1}
											disabled

										/>
									</Col>
								</Row>
							</FormBox>
						</div>
					</Col>
				</Row>
				<CommonModal
					className={openFromModal === "deleteComment" ? "deleteModal" : "statusModal"}
					title={openFromModal === "deleteComment" ? `${t("CITIZENMANAGEMENT.COMMENT")} ${t("GENERAL.DELETE__DRAWER_TITLE")}` : openFromModal === "viewAttachment" ? `${t("CITIZENMANAGEMENT.VIEW_ATTECHMENT")}` : `${t("CITIZENMANAGEMENT.COMMENT")} ${t("GENERAL.EDIT__DRAWER_TITLE")}`}
					open={data}
					onCancel={closeModal}
					footer={[
						<Button key="2" htmlType="button" className="cancelBtn" onClick={closeModal}>
							{t("GENERAL.CLOSE_BUTTON")}
						</Button>,
						openFromModal === "editComment" && (
							<Button
								key="1"
								type="primary"
								form='editCommentForms'
								loading={modalsaving}
								htmlType='submit'
								disabled={commentModalBtndisabled}
							>
								{t("GENERAL.SAVE_BUTTON")}
							</Button>
						),
						openFromModal === "deleteComment" && (
							<Button
								key="1"
								type="primary"
								loading={modalsaving}
								htmlType='submit'
								onClick={handleCommentForm}
							>
								{t("GENERAL.DELETE_BUTTON")}
							</Button>
						),

					]}
				>
					<Row className='viewFiles' gutter={[5, 20]} align={'middle'}>
						{openFromModal === "viewAttachment" &&
							data?.attachment.map((item: any, index: number) => {
								const fileExtension: any = item.url.split('.').pop();
								return (
									<Col key={index} className='viewCommentAttachments' span={8}>
										{fileExtension === "pdf" ?
											<Link to={item.url} target="_blank" rel="noopener noreferrer" className='commentPdf'>
												<FilePdfOutlined />
											</Link>

											: <Image src={item.url} className='commentImage' />
										}
									</Col>
								)

							})

						}
					</Row>

					{openFromModal === "deleteComment" &&
						<div className="deleteNote">
							{t("GENERAL.DELETE_MODEL_MESSAGE")} {t("CITIZENMANAGEMENT.COMMENT")}?
						</div>
					}

					{openFromModal === "editComment" &&
						<FormBox
							form={editCommentForm}
							id="editCommentForms"
							onFinish={handleCommentForm}
							onValuesChange={validateEditCommentForm}
							className='meetingForm'
							onChange={validateEditCommentForm}
						>
							<Row gutter={30}>

								<Col md={24} >
									<InputBox.TextArea
										required
										label={t("CITIZENMANAGEMENT.COMMENT")}
										name="comment"
										placeholder={t("CITIZENMANAGEMENT.COMMENT")}
										rows={2}
										rules={rules.comment()}

									/>
								</Col>
								<Col md={24} >
									<label className='formLabel'>{t("CITIZENMANAGEMENT.SEND_STATUS_LABEL")}</label>
									<Form.Item name="send_status" valuePropName="checked">
										<Switch
											checked={(editCommentForm.getFieldValue('send_status') === 1) ? true : false}
											onChange={(e) => {
												editCommentForm.setFieldsValue({
													send_status: e ? 1 : 0
												})
											}}
										/>
									</Form.Item>
								</Col>
								<Col md={24} >
									<InputBox.Upload
										required={false}
										label={t("GENERAL.ATTECHMENT")}
										name="attachment"
										maxCount={5}
										multiple={true}
										listType="picture"
										className="inputUpload"
										fileList={editCommentForm.getFieldValue("attachment")}
										accept=".png,.jpeg,.jpg,.pdf"
										beforeUpload={checkFileType}
										onRemove={openAttachmentDelete}
									/>
								</Col>

							</Row>
						</FormBox>
					}
				</CommonModal>
				<CommonModal
					className="deleteModal"
					title={`${t("CITIZENMANAGEMENT.DELETE_ATTECHMENT")}`}
					open={deleteAttachment}
					onCancel={closeDeletAttachmentModal}
					footer={[
						<>
							<Button
								key="2"
								htmlType="button"
								className="cancelBtn"
								onClick={closeAttachmentDelete}
							>
								{t("GENERAL.CLOSE_BUTTON")}
							</Button>
							<Button
								key="1"
								type="primary"
								loading={deleteAttachmentSaving}
								htmlType='submit'
								onClick={handleRemoveImage}
							>
								{t("GENERAL.DELETE_BUTTON")}
							</Button>
						</>
					]}
				>
					<div className="deleteNote">
						{t("GENERAL.DELETE_MODEL_MESSAGE")} {t("GENERAL.ATTECHMENT")} ?
					</div>

				</CommonModal>
			</ContentBox >
		</>
	)
}

export default MeetingView