import { RcFile } from "antd/lib/upload";
import CryptoJS from "crypto-js";
import { debounce } from "lodash";
import { CONSTANT } from "./Constant";
import moment from "moment";
import { notification } from "antd";
import store from '../store/app';
import { resetAuthStore } from "../store/AuthSlice";

export const Constant = {
  API_KEY: "secret-api-key",
};

export const handleStorageEvents = (e: any) => {
  if (e.key === 'token') {
    if (e.oldValue && !e.newValue) {
      window.location.href = "/";
      store.dispatch(resetAuthStore());
    } else if (!e.oldValue && e.newValue) {
      window.location.reload();
    }
  }
  if (e.key === 'initialData') {
    if (e.oldValue !== e.newValue) {
      window.location.reload();
    }
  }
}

export const uploadedFileOnPreview = async (file: any) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as RcFile);
      reader.onload = () => resolve(reader.result as string);
    });
  }

  const image = new Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

const insertAt = (array: any[], index: number, ...elementsArray: any[]) => {
  array.splice(index, 0, ...elementsArray);
};

export const convertTextToID = (
  textArray: any,
  mainArray: any,
  textKey = "name",
  idKey = "id"
) => {
  const newArray: any = [];
  if (textArray && textArray.values && textArray.values.length > 0) {
    textArray.values.forEach((x: any) => {
      const temp = mainArray.find((y: any) => y[textKey] === x);
      if (x && temp) {
        newArray.push(temp[idKey]);
      } else {
        insertAt(newArray, 0, x);
      }
    });
  }

  return newArray;
};

export const convertTextToIDDepatment = (
  textArray: any,
  mainArray: any,
  textKey = "dept",
  idKey = "id"
) => {
  const newArray: any = [];
  if (textArray && textArray.values && textArray.values.length > 0) {
    textArray.values.forEach((x: any) => {
      const temp = mainArray.find((y: any) => y[textKey] === x);
      if (x && temp) {
        newArray.push(temp[idKey]);
      } else {
        insertAt(newArray, 0, x);
      }
    });
  }

  return newArray;
};

export const copyTextToClipboard = (textToCopy: any) => {
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
};

export const stringEncryption = (string: string) => {
  return CryptoJS.AES.encrypt(string, Constant.API_KEY)
    .toString()
    .replace(/\+/g, "xMl3Jk")
    .replace(/\//g, "Por21Ld")
    .replace(/=/g, "Ml32");
};

export const stringDecryption = (string: string) => {
  string = string
    .replace(/xMl3Jk/g, "+")
    .replace(/Por21Ld/g, "/")
    .replace(/Ml32/g, "=");
  return CryptoJS.AES.decrypt(string, Constant.API_KEY).toString(
    CryptoJS.enc.Utf8
  );
};

// export const dataToFormDataConverter = (data: any) => {
//   const formData = new FormData();
//   for (let name in data) {
//     const value = data[name];

//     if (value !== undefined && value !== null) {
//       if (Array.isArray(value)) {
//         name = `${name}[]`;
//         value.map(item => {
//           formData.append(name, item);
//           return item;
//         });
//       } else {
//         formData.append(name, value);
//       }
//     }
//     // formData.append(name, data[name]); // there should be values.avatar which is a File object
//   }
//   return formData;
// };
export const dataToFormDataConverter = (
  data: any,
  appendName?: any,
  appendFormData?: any
) => {
  let formData: any;
  if (!appendFormData) {
    formData = new FormData();
  } else {
    formData = appendFormData;
  }
  for (let name in data) {
    let value = data[name];

    if (value !== undefined && value !== null) {
      // console.log("Array.isArray(value)", Array.isArray(value));

      if (Array.isArray(value)) {
        if (appendName) {
          name = `${appendName}[${name}][]`;
        } else {
          name = `${name}[]`;
        }
        value.map((item) => {
          if (item.originFileObj) {
            item = item.originFileObj;
          }
          formData.append(name, item);
          return item;
        });
      } else if (
        !value.fileList &&
        typeof value === "object" &&
        value !== null
      ) {
        if (appendName) {
          dataToFormDataConverter(value, `${appendName}[${name}]`, formData);
        } else {
          dataToFormDataConverter(value, `${name}`, formData);
        }
      } else {
        if (value.originFileObj) {
          value = value.originFileObj;
        }
        if (appendName) {
          formData.append(`${appendName}[${name}]`, value);
        } else {
          formData.append(`${name}`, value);
        }
      }
    }
    // formData.append(name, data[name]); // there should be values.avatar which is a File object
  }
  return formData;
};

export const dataToFormDataConverterNew = (
  data: any,
  appendName?: any,
  appendFormData?: any
) => {
  let formData: any;
  if (!appendFormData) {
    formData = new FormData();
  } else {
    formData = appendFormData;
  }
  for (const name in data) {
    let value = data[name];

    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item.originFileObj) {
            item = item.originFileObj;
          }
          const fieldName = appendName
            ? `${appendName}[${name}][${index}]`
            : `${name}[${index}]`;
          formData.append(fieldName, item);
        });

      } else if (
        !value.fileList &&
        typeof value === "object" &&
        value !== null
      ) {
        if (appendName) {
          dataToFormDataConverter(value, `${appendName}[${name}]`, formData);
        } else {
          dataToFormDataConverter(value, `${name}`, formData);
        }
      } else {
        if (value.originFileObj) {
          value = value.originFileObj;
        }
        if (appendName) {
          formData.append(`${appendName}[${name}]`, value);
        } else {
          formData.append(`${name}`, value);
        }
      }
    }
  }
  return formData;
};


export const dataToFormDataConverterA = (data: any, appendName?: any, appendFormData?: any) => {
  let formData: any;
  if (!appendFormData) {
    formData = new FormData();
  } else {
    formData = appendFormData;
  }
  for (const name in data) {
    let value = data[name];

    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item.originFileObj) {
            item = item.originFileObj;
          }
          const fieldName = appendName ? `${appendName}[${name}][${index}]` : `${name}[${index}]`;
          formData.append(fieldName, item);
        });
      } else if (!value.originFileObj && typeof value === "object" && value !== null) {
        if (appendName) {
          dataToFormDataConverterA(value, `${appendName}[${name}]`, formData);
        } else {
          dataToFormDataConverterA(value, `${name}`, formData);
        }
      } else {
        if (value.originFileObj) {
          value = value.originFileObj;
        }
        if (appendName) {
          formData.append(`${appendName}[${name}]`, value);
        } else {
          formData.append(`${name}`, value);
        }
      }
    }
  }
  return formData;
};

export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
): File => {
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new File(byteArrays, "pot", { type: contentType });
};

export const downloadFile = (base64Data: any, fileType: any, fileName: any) => {
  const b64Data = b64toBlob(base64Data, fileType)
  const fileUrl = URL.createObjectURL(b64Data);
  const downloadLink = document.createElement("a");
  downloadLink.href = fileUrl;
  downloadLink.download = fileName; // You can set the filename here
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(fileUrl);
}

export const validateFields = debounce((form, setDisabled) => {
  form
    .validateFields()
    .then(() => {
      setDisabled(false);
    })
    .catch(() => {
      setDisabled(true);
    });
}, 500);

export const dateFormatter = (
  date: any,
  format = CONSTANT.DATE_FORMAT
) => {
  return moment(date).format(format);
};

export const snakeCaseString = (str: any): string => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((s: any) => s.toLowerCase())
      .join("_")
  );
};

export const camelCaseString = (str: string): string => {
  str = str
    .replace(/[-_]+/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: any) {
      return index !== 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/ (.)/g, function ($1: any) {
      return $1.toUpperCase();
    });
  return str;
};

/**
 * We use notification from here 2 type of nofiifcation
 * Success: {placement:"bottomRight",message: "Your Success message"}
 * Error: {placement:"bottomRight",message: "Your Error Message"}
 */
export const Notification = {
  success: (data: any) => {
    notification.success({
      placement: data.placement ? data.placement : "bottomRight",
      duration: 3,
      ...data,
    });
  },
  error: (data: any) => {
    notification.error({
      placement: data.placement ? data.placement : "bottomRight",
      duration: 3,
      ...data,
    });
  },
};

export const checkPrivileges = (
  userDetail: any,
  permissionType: string
): boolean =>
  !!(
    userDetail?.userPrivileges &&
    userDetail?.userPrivileges.includes(permissionType)
  );

export const isActiveStatusValue = [
  {
    id: 0,
    name: "No"
  },
  {
    id: 1,
    name: "Yes",
  }

]
//Dropdowns
export const genderOptions = [
  { id: 1, name: CONSTANT.GENDER_TYPES.MALE },
  { id: 2, name: CONSTANT.GENDER_TYPES.FEMALE },
  { id: 3, name: CONSTANT.GENDER_TYPES.OTHER },
];
export const genderOptionsDisplay: any = {
  1: CONSTANT.GENDER_TYPES.MALE,
  2: CONSTANT.GENDER_TYPES.FEMALE,
  3: CONSTANT.GENDER_TYPES.OTHER,
};

export const villageType = [
  { id: 1, name: CONSTANT.VILLAGE_TYPE.RURAL },
  { id: 2, name: CONSTANT.VILLAGE_TYPE.URBAN },
];
export const villageTypeDisplay: any = {
  1: CONSTANT.VILLAGE_TYPE.RURAL,
  2: CONSTANT.VILLAGE_TYPE.URBAN,
};
export const meetingStatusOptions: any = [
  { id: 1, name: CONSTANT.MEETING_TYPE.IN_PROGRESS },
  { id: 2, name: CONSTANT.MEETING_TYPE.CLOSE },
];
export const meetingDisplay: any = {
  1: CONSTANT.MEETING_TYPE.IN_PROGRESS,
  2: CONSTANT.MEETING_TYPE.CLOSE,
};
export const partyPersonType = [
  { id: 1, name: CONSTANT.PARTY_PERSONS_TYPE.PARTY_WORKER },
  { id: 2, name: CONSTANT.PARTY_PERSONS_TYPE.ELECTED_MEMBERS },
  { id: 3, name: CONSTANT.PARTY_PERSONS_TYPE.COOPERATIVE_LEADERS }
]
export const partyPersonTypeDisplay: any = {
  1: CONSTANT.PARTY_PERSONS_TYPE.PARTY_WORKER,
  2: CONSTANT.PARTY_PERSONS_TYPE.ELECTED_MEMBERS,
  3: CONSTANT.PARTY_PERSONS_TYPE.COOPERATIVE_LEADERS,
};
export const EntryFromOptions = [
  { id: 1, name: CONSTANT.ENTRY_FROM_TYPES.SAVERKUNDLA },
  { id: 2, name: CONSTANT.ENTRY_FROM_TYPES.MANINAGAR },
];
export const EntryFromOptionsTypeDisplay: any = {
  1: CONSTANT.ENTRY_FROM_TYPES.SAVERKUNDLA,
  2: CONSTANT.ENTRY_FROM_TYPES.MANINAGAR,
};
export const GrantStatusList = [
  { id: 1, name: CONSTANT.GrantStatusList.Date_of_Preliminary_Approval },
  { id: 2, name: CONSTANT.GrantStatusList.Tantric_approval_Date },
  { id: 3, name: CONSTANT.GrantStatusList.Date_of_Administration_approval },
  { id: 4, name: CONSTANT.GrantStatusList.Grant_Allotment_Authority_Date },
]
export const GrantStatusListDisplay: any = {
  1: CONSTANT.GrantStatusList.Date_of_Preliminary_Approval,
  2: CONSTANT.GrantStatusList.Tantric_approval_Date,
  3: CONSTANT.GrantStatusList.Date_of_Administration_approval,
  4: CONSTANT.GrantStatusList.Grant_Allotment_Authority_Date,
};
export const OfficeList = [
  { id: 1, name: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.State },
  { id: 2, name: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.District },
  { id: 3, name: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.Taluka },
  { id: 4, name: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.Municipality },
  { id: 5, name: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.Village },
]
export const OfficeListDisplay: any = {
  1: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.State,
  2: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.District,
  3: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.Taluka,
  4: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.Municipality,
  5: CONSTANT.GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE.Village,
};
export const mobileValidation = (mobile: any) => {
  if (mobile && typeof mobile === 'string') {
    var wrongNumberArr = ["6666666666", "7777777777", "8888888888", "9999999999", "0000000000"];
    var staringStr = ["0", "1", "2", "3", "4", "5"];
    var stringWith = mobile.substring(0, 1);
    if (mobile.length === 10 && !wrongNumberArr.includes(mobile) && !staringStr.includes(stringWith)) {
      return true;
    }
  }
  return false;
}
export const attractionDisplay: any = {
  1: CONSTANT.ATTRACTION_TYPE.TOURIST,
  2: CONSTANT.ATTRACTION_TYPE.RELIGIOUS,

};
export const attractionOptionsType = [
  { id: 1, name: CONSTANT.ATTRACTION_TYPE.TOURIST },
  { id: 2, name: CONSTANT.ATTRACTION_TYPE.RELIGIOUS },
];

export const tablePagination = {
  pageSize: 10,
  current: 1,
  showSizeChanger: false,
  pageSizeOptions: ['10', '20', '50', '100'],
}